import React from 'react'
import { Navbar, Nav } from 'rsuite';
import './Topbar.css';
import logo from '../../assets/logo.svg';

const Topbar = () => (
  <div className="logo-wrapper">
    <img src={logo} className="App-logo" alt="logo" />
  </div>
)

export default Topbar;
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { setVisibilityFilter } from '../actions';

const Filters = ({filter, changeFilter}) => {
  useEffect(()=>{
    console.log({filter});
  }, [filter]);

  return (
    <p className='filterTodo'>
      Mostrar: 
      {" "}
      <a onClick={()=>{changeFilter('SHOW_ALL')}}>
        Todos
      </a>
      {", "}
      <a onClick={()=>{changeFilter('SHOW_ACTIVE')}}>
        Activos
      </a>
      {", "}
      <a onClick={()=>{changeFilter('SHOW_COMPLETED')}}>
        Completados
      </a>
    </p>
  )
}


const mapStateToProps = (state, ownProps) => ({
 filter: state.filter,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilter: (filter) => {
    dispatch(setVisibilityFilter(filter))}
});


export default connect(mapStateToProps, mapDispatchToProps)(Filters);

import React, { useEffect } from 'react'
import TrashIcon from '@rsuite/icons/Trash';
import MinusIcon from '@rsuite/icons/Minus';
import PlusIcon from '@rsuite/icons/Plus';
import PropTypes from 'prop-types';
import { List } from 'rsuite';

const TodoList = ({ todos, onTodoClick, onDeleteClick }) => {
  const [data, setData] = React.useState([]);

  useEffect(()=>{
    setData(todos);
  }, [todos])

  const handleSortEnd = ({ oldIndex, newIndex }) =>
    setData(prvData => {
      const moveData = prvData.splice(oldIndex, 1);
      const newData = [...prvData];
      newData.splice(newIndex, 0, moveData[0]);
      return newData;
    }, []);

  return (
    <List sortable onSort={handleSortEnd} className="list-wrapper">
      {data.map(({ id, text, completed }, index) => (
        <div className="list-item-wrapper">
          <div className="icon-wrapper" onClick={() => onTodoClick(id)}>
            {completed
            ? <PlusIcon />
            : <MinusIcon />}
          </div>
          <List.Item
            key={`${id}-${text}`}
            index={index}
            className="list-item"
            style={{
              textDecoration: completed ? 'line-through' : 'none'
            }}
          >
            {text}
          </List.Item>
          <div className="icon-wrapper trash-icon" onClick={() => onDeleteClick(id)}><TrashIcon /></div>
        </div>
      ))}
    </List>
  );
}

TodoList.propTypes = {
  todos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired).isRequired,
  onTodoClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}

export default TodoList
const todo = (state = {}, action) => {
    switch (action.type) {
      case 'ADD_TODO':
        const newState =  {
          id: action.id,
          text: action.text,
          completed: false
        };
        console.log({ type:'ADD_TODO', oldState: state, newState })
        return newState;
          
      case 'TOGGLE_TODO':
        if (state.id !== action.id) {
          return state
        }
        const newState_toggle =  {
          ...state,
          completed: !state.completed
        };
        console.log({ type: 'TOGGLE_TODO', oldState: state, newState: newState_toggle })
        return Object.assign({}, state, {
          completed: !state.completed
        });
      case 'DELETE_TASK':
        if (state.id !== action.id) {
          return state
        }
        console.log(state);
        const newState_delete =  {
          ...state
        };
        console.log({ type: 'DELETE_TASK', oldState: state, newState: {newState_delete} })
        return Object.assign({}, {});
      default:
        return state
    }
  }
  
  const todos = (state = [], action) => {
    switch (action.type) {
      case 'ADD_TODO':
        return [
          ...state,
          todo(undefined, action)
        ]
      case 'TOGGLE_TODO':
        return state.map(t =>
          todo(t, action)
        )
      case 'DELETE_TASK':
        return state.map(t =>
          todo(t, action)
        )
      default:
        return state
    }
  }
  
  export default todos
import React, { useState } from 'react'
import { connect } from 'react-redux'
import IconButton from 'rsuite/IconButton';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { addTodo } from '../actions'
import { Form } from 'rsuite';

let AddTodo = ({ dispatch }) => {
  const [task, setTask] = useState("");

  const onSubmit = () => {
    if (!task.trim()) {
      return
    }
    dispatch(addTodo(task))
    setTask('');
  }

  return (
    <div >
      <Form className="inline-form" onSubmit={e => (onSubmit())}>
      <Form.Group controlId="task">
          <Form.Control placeholder="Agrega tus tareas para hacer" name="task" value={task} onChange={e=> setTask(e)} autoComplete="off" />
        </Form.Group>
      <Form.Group controlId="add-button">
        <IconButton icon={<AddOutlineIcon />} appearance="primary" type="submit" />
        </Form.Group>
      </Form>
    </div>
  )
}

AddTodo = connect()(AddTodo)

export default AddTodo

import './App.css';
import Filters from '../../components/Filters';
import AddTodo from '../AddTodo';
import VisibleTodoList from '../VisibleTodoList';
import { Container } from 'rsuite';
import Topbar from '../../components/Topbar/Topbar';

function App() {
  return (
    <div className="fullHeight">
      <Container className='fullHeight'>
        <div className="card">
        <Topbar />
          <AddTodo />
          <VisibleTodoList />
          <Filters />
        </div>
      </Container>
    </div>
  );
}

export default App;
